
export const SERVICE1 = '法人・個人事業主向けサービス';
export const SERVICE1_DESCRIPTION = '単純な会計税務の作業だけでなく、これまでの経験とノウハウでお客様にとって価値のあるサービス、提案を提供致します。\n' +
    '税務顧問は毎月訪問を原則としていますが、ご予算・必要度に応じて隔月訪問などオーダーメイド型の対応も可能です。';
export const SERVICE2 = '相続関連サービス';
export const SERVICE2_DESCRIPTION = '';
export const SERVICE0 = '当事務所の特徴';
export const SERVICE0_DESCRIPTION = '初回相談は完全無料で対応します。';

export const SERVICE1_LIST =['税務顧問(税務&会計)','決算申告書作成、確定申告書作成','経営相談','節税対策','経営計画の策定支援',
    '税務調査の立ち会い','保険指導','開業・会社設立支援','自計化システムの導入支援']
export const SERVICE2_LIST = ['相続税申告','相続税試算','相続税対策']
export const SERVICE0_LIST = ['初回相談は完全無料で対応します','全ての案件に代表が直接対応します','複雑かつ多様化したニーズに応えるため他の専門家と連携し総合的にサポートします']

export const mailAddress:string = 'E-mail:contact@tax-ohata.com';

export const titleAndContent: {title:string,content:string }[] = [
    {
        title: 'Profile',
        content:'1982年、石川県小松市出身。明治大学商学部卒業。\n' +
            '大学卒業後、新聞社で広告の営業を経験。\n' +
            'その後、3つの税理士事務所で約15年間個人・法人の税務について幅広い業務を経験。\n\n' +
            '2019年1月 税理士資格取得\n' +
            '2023年11月 大幡周平税理士事務所税理士を設立'
        ,
    },
    {
        title: 'OVERVIEW事務所概要',
        content:'名称:大幡周平税理士事務所\n' +
            '代表者名:大幡周平\n' +
            '所在地:〒143-0011 東京都大田区大森本町1-8-10\n' +
            'Tel:090-8966-2307\n' +
            '設立:2023年11月\n' +
            '営業時間:平日 8:00~17:00 (ご連絡いただければ土日、時間外も対応いたします)\n' +
            '主な対応地域:東京都、神川県、千葉県\n' +
            '所属:東京税理士会 大森支部 所属\n'
    },
]